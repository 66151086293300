<template>
  <section class="container text-transparencia">
    <layout-breadcrumb :items="breadcrumbs"></layout-breadcrumb>
    <div class="row">
      <div class="col-sm-12">
        <h2 class="text-transparencia">
          <!-- <a @click="$router.go(-1)"><i class="fa fa-arrow-left cr-po mr-5" data-toggle="tooltip" data-placement="top" title="Volver"></i></a> -->
          Publicaciones
        </h2>
        <p class="mt-1 mb-5 text-transparencia">Seleccione una categoría</p>
      </div>
    </div>

    <div class="row justify-content-center text-center">
      <template v-for="(categoria, index) in categorias">
        <div class="col-md-4 mt-2">
          <a :id="categoria.slug" :href="'#' + categoria.slug" class="btn btn-block transition-hover px-3 rounded-pill" :class="categoriaActual == categoria.publicacionCategoriaId ? 'btn-secondary' : 'btn-transparencia'" v-on:click="mostrarPanel(categoria.publicacionCategoriaId)">{{ categoria.nombre }}</a>
        </div>
      </template>
    </div>

    <div class="row justify-content-center m-gab-1-top">
      <template v-for="(publicacion, key) in publicaciones">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-5" :key="key" v-show="categoriaActual == publicacion.publicacionCategoriaId">
          <div class="card card-frame card-owework  border hover-shadow-6 d-block transition-hover">
            <div class="card-header pt-4" style="background-color: transparent !important;border-bottom: 0 !important;height:60px;">
              <h6 class="text-dark fw-600 text-center ">{{ publicacion.titulo }}</h6>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 text-center pl-4 pr-4">
                  <img alt="" class="img-fluid mt-2 mb-2" style="height:420px;width:auto;" :src="`${fileUrl(publicacion.rutaImagen)}`" :alt="publicacion.titulo" />
                </div>
                <div class="col-md-12">
                  <p class="text-transparencia fs-16 mb-1 mt-1 p-2">
                    {{ publicacion.sumilla }}
                  </p>
                  <div class="d-flex justify-content-between p-2">
                    <small class="fs-12">Peso: {{ publicacion.tamanio }}</small>
                    <small class="fs-12">Formato: <span class="text-uppercase">{{ publicacion.extension }}</span></small>
                  </div>
                  <!-- <div class="text-center">
                </div> -->
                </div>
              </div>
            </div>
            <div class="card-footer text-center pb-4">
              <a class="btn btn-transparencia btn-sm fw-600 text-center pl-5 pr-5" :href="`${filesUrl + publicacion.rutaArchivoPdf}`" download> <i class="fa fa-download"></i> DESCARGAR</a>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import layoutBreadcrumb from "@/layout/layout-breadcrumb";
export default {
  name: "view-publicacion",
  metaInfo: {
    title: "Publicaciones",
  },
  data: () => ({
    publicaciones: [],
    categorias: [],
    categoriaActual: null,
  }),
  components: { layoutBreadcrumb },
  methods: {
    mostrarPanel: function (publicacionCategoriaId) {
      this.categoriaActual = publicacionCategoriaId;
    },
    fetchDatos: async function () {
      await this.$http.get(this.apiRoute("Common", "ListPublicacionesAll")).then(function (response) {
        if (!response.body.error) {
          this.publicaciones = response.body.data.lista;
          // if (this.publicaciones != null && this.publicaciones.length > 0) {
          //   this.categoriaActual = this.publicaciones[0].publicacionCategoriaId;
          // }
          this.categorias = response.body.data.categorias;
          if (this.categorias != null && this.categorias.length > 0) {
            this.categoriaActual = this.categorias[0].publicacionCategoriaId;
          }

          this.setSlug();
        }
      });
    },
    setSlug: async function () {
      let fullPath = this.$route.fullPath;
      let slug = "";
      if (fullPath.indexOf("#") > -1) {
        slug = fullPath.split("#")[1];
        //console.log(slug);
        let encontrado = this.categorias.find((x) => x.slug == slug);
        if (encontrado) {
          this.mostrarPanel(encontrado.publicacionCategoriaId);
        }
      }
    },
  },
  mounted: async function () {
    await this.fetchDatos();
    this.breadcrumbs = [
      { name: "Inicio", url: "/home", icon: "fa fa-home", active: false },
      { name: "Publicaciones", url: "", icon: "", active: true },
    ];
  },
};
</script>
